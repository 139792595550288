import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./SettingsPage.css";
import { StoryContext } from "./StoryContext";

const SettingsPage = () => {
  const { settingsData, setSettingsData } = useContext(StoryContext);
  const navigate = useNavigate();

  const handleSaveSettings = () => {
    // Logic to save settings if needed
    navigate("/form"); // Change this to navigate to the FormPage instead of HomePage
  };

  const handleBack = () => {
    // Navigate back to FormPage when clicking back button
    navigate("/form");
  };

  return (
    <div className="settings-container">
      {/* Back button to navigate back to FormPage */}
      <button className="back-button" onClick={handleBack}>
        &#8249;
      </button>
      
      <h2 className="settings-title">Story Customization</h2>

      <div className="settings-section">
        <h3>Content Sensitivity</h3>
        <select
          value={settingsData.violenceLevel}
          onChange={(e) =>
            setSettingsData({ ...settingsData, violenceLevel: e.target.value })
          }
        >
          <option value="No Scary Elements">No Scary Elements</option>
          <option value="Mild Scary Elements">Mild Scary Elements</option>
          <option value="Educational Content Only">Educational Content Only</option>
        </select>
      </div>

      <div className="settings-section">
        <h3>Language Level</h3>
        <select
          value={settingsData.languageLevel}
          onChange={(e) =>
            setSettingsData({ ...settingsData, languageLevel: e.target.value })
          }
        >
          <option value="Ages 2-3">Ages 2-3</option>
          <option value="Ages 4-6">Ages 4-6</option>
          <option value="Ages 7-9">Ages 7-9</option>
          <option value="Ages 10-12">Ages 10-12</option>
          <option value="Teens">Teens</option>
        </select>
      </div>

      <div className="settings-section">
        <h3>Moral Themes</h3>
        <select
          value={settingsData.moralTheme}
          onChange={(e) =>
            setSettingsData({ ...settingsData, moralTheme: e.target.value })
          }
        >
          <option value="Friendship">Friendship</option>
          <option value="Courage">Courage</option>
          <option value="Honesty">Honesty</option>
          <option value="Kindness">Kindness</option>
          <option value="Respect">Respect</option>
          <option value="Teamwork">Teamwork</option>
          <option value="Responsibility">Responsibility</option>
        </select>
      </div>

      <div className="settings-section">
        <h3>Story Tone</h3>
        <select
          value={settingsData.storyTone}
          onChange={(e) =>
            setSettingsData({ ...settingsData, storyTone: e.target.value })
          }
        >
          <option value="Light-hearted">Light-hearted</option>
          <option value="Adventurous">Adventurous</option>
          <option value="Suspenseful">Suspenseful</option>
          <option value="Educational">Educational</option>
          <option value="Inspirational">Inspirational</option>
          <option value="Mystical">Mystical</option>
        </select>
      </div>

      <h2 className="settings-title">Visual & Audio Customization</h2>

      <div className="settings-section">
        <h3>Background Theme</h3>
        <select
          value={settingsData.backgroundTheme}
          onChange={(e) =>
            setSettingsData({
              ...settingsData,
              backgroundTheme: e.target.value,
            })
          }
        >
          <option value="Fantasy Forest">Fantasy Forest</option>
          <option value="Ocean Depths">Ocean Depths</option>
          <option value="Starlit Sky">Starlit Sky</option>
          <option value="Desert Mirage">Desert Mirage</option>
          <option value="Snowy Mountains">Snowy Mountains</option>
          <option value="Robotic City">Robotic City</option>
          <option value="Enchanted Castle">Enchanted Castle</option>
          <option value="Ancient Ruins">Ancient Ruins</option>
          <option value="Floating Islands">Floating Islands</option>
        </select>
      </div>

      <div className="settings-section">
        <h3>Voice Type</h3>
        <select
          value={settingsData.voiceType}
          onChange={(e) =>
            setSettingsData({ ...settingsData, voiceType: e.target.value })
          }
        >
          <option value="Friendly">Friendly</option>
          <option value="Heroic">Heroic</option>
          <option value="Mystical">Mystical</option>
          <option value="Comedic">Comedic</option>
          <option value="Narrator">Narrator</option>
          <option value="Gentle">Gentle</option>
        </select>
      </div>

      <div className="settings-section">
        <h3>Background Music</h3>
        <select
          value={settingsData.backgroundMusic}
          onChange={(e) =>
            setSettingsData({
              ...settingsData,
              backgroundMusic: e.target.value,
            })
          }
        >
          <option value="Soothing">Soothing</option>
          <option value="Epic">Epic</option>
          <option value="Mystical">Mystical</option>
          <option value="Nature Sounds">Nature Sounds</option>
          <option value="Lullaby">Lullaby</option>
          <option value="Adventure">Adventure</option>
          <option value="Classical">Classical</option>
          <option value="Ambient Fantasy">Ambient Fantasy</option>
          <option value="None">None</option>
        </select>
      </div>

      <div className="save-settings">
        <button onClick={handleSaveSettings}>Save Settings</button>
      </div>
    </div>
  );
};

export default SettingsPage;


