import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Storyboard.css";
import { API_ENDPOINT } from "./var.js";

const Storyboard = () => {
  const { storyId } = useParams();
  const navigate = useNavigate();
  const [story, setStory] = useState(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [prefetchedImages, setPrefetchedImages] = useState({});

  useEffect(() => {
    const fetchStory = async () => {
      const response = await fetch(`${API_ENDPOINT}/story/${storyId}`);
      if (response.ok) {
        const data = await response.json();
        setStory(data);
      } else {
        console.error("Failed to fetch story data");
      }
    };
    fetchStory();
  }, [storyId]);

  const generateImage = useCallback(
    async (sceneIndex) => {
      const scene = story?.scenes?.[sceneIndex];
      if (!scene) {
        console.error("Invalid scene data");
        return;
      }

      const response = await fetch(`${API_ENDPOINT}/generate-image`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          storyId,
          sceneIndex,
          imagePrompt: scene.imagePrompt,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setStory((prevStory) => ({
          ...prevStory,
          scenes: prevStory.scenes.map((scene, index) =>
            index === sceneIndex ? { ...scene, imageUrl: data.imageUrl } : scene
          ),
        }));
        return data.imageUrl;
      } else {
        console.error("Failed to generate image");
        return null;
      }
    },
    [story, storyId]
  );

  const prefetchNextImage = useCallback(
    async (index) => {
      if (
        story &&
        story.scenes &&
        story.scenes[index] &&
        !story.scenes[index].imageUrl &&
        !prefetchedImages[index]
      ) {
        const imageUrl = await generateImage(index);
        if (imageUrl) {
          setPrefetchedImages((prev) => ({ ...prev, [index]: imageUrl }));
        }
      }
    },
    [story, generateImage, prefetchedImages]
  );

  useEffect(() => {
    if (story && story.scenes) {
      // Prefetch current image if not available
      if (!story.scenes[currentSceneIndex].imageUrl) {
        generateImage(currentSceneIndex);
      }
      // Prefetch next image
      prefetchNextImage(currentSceneIndex + 1);
    }
  }, [story, currentSceneIndex, generateImage, prefetchNextImage]);

  const handleTextToSpeech = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const speech = new SpeechSynthesisUtterance(
        story.scenes[currentSceneIndex]?.text || "No scene text available"
      );
      speech.onend = () => setIsSpeaking(false);
      window.speechSynthesis.speak(speech);
      setIsSpeaking(true);
    }
  };

  const handlePrevious = () => {
    setCurrentSceneIndex((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentSceneIndex((prev) => {
      const next = Math.min(story.scenes.length - 1, prev + 1);
      // Prefetch the image after the next one
      prefetchNextImage(next + 1);
      return next;
    });
  };

  //  const handleSaveAllImages = async () => {
  //   if (!story || !story.scenes) return;

  //   const allImageUrls = story.scenes
  //     .map((scene) => scene.imageUrl)
  //     .filter(Boolean);

  //   try {
  //     const response = await fetch(`${API_ENDPOINT}/save-images`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         storyId,
  //         imageUrls: allImageUrls,
  //       }),
  //     });

  //     if (response.ok) {
  //       alert("All images saved successfully!");
  //     } else {
  //       throw new Error("Failed to save images");
  //     }
  //   } catch (error) {
  //     console.error("Error saving images:", error);
  //     alert("Failed to save images. Please try again.");
  //   }
  // };

  const handleClose = () => {
    navigate("/story");
  };

  if (!story) return <div>Loading...</div>;

  const currentScene = story.scenes[currentSceneIndex];

  return (
    <div className="storyboard-container">
      {/* Scene background image */}
      <div
        className="scene-image"
        style={{ backgroundImage: `url(${currentScene.imageUrl})` }}
      >
        {/* Header with back and bookmark buttons */}
        <div className="story-header">
          <button className="back-button" onClick={handleClose} />
          <h2 className="chapter-title">Chapter {currentSceneIndex + 1}</h2>
          <button className="bookmark-button">
            <img
              src={process.env.PUBLIC_URL + "/icons/bookmark.png"}
              alt="Bookmark"
            />
          </button>
        </div>

        {/* Story Text Box */}
        <div className="story-text-box">
          <h3>A Journey of Friendship</h3>
          <p className="story-text">{currentScene.text}</p>
        </div>
      </div>

      {/* Playback controls */}
      <div className="controls">
        <button
          className="previous-button"
          onClick={handlePrevious}
          disabled={currentSceneIndex === 0}
        >
          <img
            src={process.env.PUBLIC_URL + "/icons/previous.png"}
            alt="Previous"
          />
        </button>
        <button className="play-button" onClick={handleTextToSpeech}>
          <img
            src={
              isSpeaking
                ? process.env.PUBLIC_URL + "/icons/pause.png"
                : process.env.PUBLIC_URL + "/icons/play.png"
            }
            alt="Play/Pause"
          />
        </button>
        <button
          className="next-button"
          onClick={handleNext}
          disabled={currentSceneIndex === story.scenes.length - 1}
        >
          <img src={process.env.PUBLIC_URL + "/icons/next.png"} alt="Next" />
        </button>
        <button className="settings-button">
          <img
            src={process.env.PUBLIC_URL + "/icons/settings.png"}
            alt="Settings"
          />
        </button>
      </div>
    </div>
  );
};

export default Storyboard;
