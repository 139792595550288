// Dashboard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const goBack = () => {
    navigate('/home'); // Navigates back to HomePage
  };

  const stories = [
    { id: 1, title: "The Enchanted Forest" },
    { id: 2, title: "Pirate's Treasure" },
    { id: 3, title: "Journey to the Stars" },
  ];

  return (
    <div className={`dashboard-container ${darkMode ? 'dark-mode' : ''}`}>
      {/* Back Button */}
      <button className="back-button" onClick={goBack} aria-label="Go Back">
        &#8249; {/* Unicode less-than symbol resembling a back arrow */}
      </button>

      {/* Header */}
      <header className="dashboard-header">
        {/* Dashboard Title */}
        <h1 className="dashboard-title">Dashboard</h1>
      </header>

      {/* Profile Picture */}
      <div className="profile-picture"></div>

      {/* Story History */}
      <h2 className="story-history">Story History</h2>

      {/* Story List */}
      <div className="story-list">
        {stories.map((story) => (
          <div key={story.id} className="story-card">
            <h2 className="story-title">{story.title}</h2>
            <button
              className="story-button"
              onClick={() => navigate(`/storyboard/${story.id}`)}
            >
              View
            </button>
          </div>
        ))}
      </div>

      {/* Create New Story Button */}
      <button className="create-story-button" onClick={() => navigate('/form')}>
        + Create New Adventure
      </button>

      {/* Dark Mode Toggle */}
      <button className="dark-mode-toggle" onClick={toggleDarkMode} aria-label="Toggle Dark Mode">
        {darkMode ? '☀️' : '🌙'}
      </button>
    </div>
  );
};

export default Dashboard;






































