import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScreenOne from "./ScreenOne";
import HomePage from "./HomePage";
import FormPage from "./FormPage";
import SettingsPage from "./SettingsPage";
import Dashboard from "./Dashboard";
import Story from "./Story";
import Storyboard from "./Storyboard";
import { StoryProvider } from "./StoryContext";

function App() {
  return (
    <StoryProvider>
      <Router>
        <Routes>
          <Route path="/" element={<ScreenOne />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/form" element={<FormPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/story" element={<Story />} />
          <Route path="/storyboard/:storyId" element={<Storyboard />} />
        </Routes>
      </Router>
    </StoryProvider>
  );
}

export default App;



